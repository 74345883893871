/* Base Container */
.nurse-roster-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* height: calc(100vh - 60px);  */
  height: fit-content;
  padding: 20px;
  box-sizing: border-box;
}

/* Top Row */
.top-row {
  display: flex;
  gap: 20px;
  height: 50vh;
  min-height: 350px;
}

/* Columns */
.column {
  flex: 1;
  background-color: #f4f4f4;
  border-radius: 8px;
  /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); */
  padding: 10px;
  overflow-y: auto;
  position: relative;
  transition: all 0.3s ease;
}

.column::-webkit-scrollbar {
  width: 5px;
}

.column::-webkit-scrollbar-track {
  background: #fff;
  width: 10px;
}

.column::-webkit-scrollbar-thumb {
  background-color: #8b7d7db0;
  border-radius: 10px;
}

.column::-webkit-scrollbar-thumb:hover {
  background: #605e57b6;
  cursor: pointer;
}

/* Bottom Row */
.bottom {
  height: 33%;
  /* One-third height */
}

/* Expanded State */
.expandeda {
  position: fixed;
  top: 60px;
  /* Account for navbar height */
  left: 0;
  width: 100%;
  height: calc(100vh - 60px);
  /* Full-screen height minus navbar */
  z-index: 10;
  padding: 20px;
  box-sizing: border-box;
}

/* Expand Icon */
.expand-icon {
  font-size: 18px;
  color: #666;
  cursor: pointer;
}

.expand-icon:hover {
  color: #000;
}
