.dialysis-container {
    margin: 1vh 10%;
    box-shadow: 0px 0px 4px 3px rgba(135, 135, 135, 0.25);
    border: 1px solid #C3CAD1;
    background: rgba(255, 255, 255, 1);
    border-radius: 10px;
    height: fit-content;
    padding: 2vh;
}

.peace-image {
    float: left;
    margin: 1vh;
    width: 150px;
    margin-bottom: 0;
    align-self: flex-end;
}

.dialysis-head {
    margin: 0;
    float: left;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 29.05px;
    color: #2456AE !important;
    text-align: center;
    align-self: flex-end;
}

.dialysis-container .lines {
    color: #666666;
    margin: 1px;
    font-weight: 400;
    opacity: 1;
}

.dialysis-formelements {
    margin: 0 2vh;
}

.input-container {
    display: flex;
    flex-direction: column;
    height: 78px;
}

.input-containersp {
    display: flex;
    flex-direction: column;
    height: 88px;
}

.input1 {
    border: 1px solid #CCCCCC;
    border-radius: 8px;
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.invoice-input1 {
    display: flex;
    justify-content: center;
    align-items: center;
}

.file-input {
    border: 1px solid #CCCCCC;
    border-radius: 8px;
    height: 46px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.preview-container {
    display: flex;
    height: 95% !important;
}

.file-preview {
    display: flex;
    max-width: 30%;
    overflow: hidden;
    text-overflow: ellipsis;
    justify-content: space-between;
    align-items: center;
    padding: 2px;
    border: 1px solid #CCCCCC;
    border-radius: 4px;
    margin: 2px;
}

.file-remove-button {
    color: #FF0000;
    cursor: pointer;
    height: 20px;
    width: 20px;
}

.upload-icon {
    height: 1.7rem;
    width: 1.7rem;
    color: #007DCE;
}

.input1 .col-4 {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.invoice-input1 .col-4,
.invoice-input1 .col-5,
.invoice-input1 .col-3
.invoice-input1 .col-9 {
    flex: 1;
    display: flex;
    align-items: center;
}

.dialysis-profile {
    margin-top: 1%;
    color: #2456AE;
    border: 1px solid #2456AE;
    border-radius: 8px;
    width: 100%;
    height: 9rem;
    background-color: #e9e9e98c;
    overflow: hidden;
}

.dialysis-profile .profile-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.dialysis-profile .uploaded {
    border-radius: 8px;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
}

.input-container label,
.input-containersp label {
    color: #666666;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    margin: 0;
    padding-left: 2px;
}

.side-head {
    margin: 10px 0;
    padding: 0;
}

.side-head .col-2,
.side-head .col-10 {
    padding: 0;
    align-content: center;
}

.side-head label {
    margin: 0;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: #666666;
}

.dialysis-container .save {
    background-color: #2456AE;
    width: 160px;
    height: 46px;
    border-radius: 15px;
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 400;
    align-self: center;
    margin: auto;
}

.dialysis-container .react-date-picker__wrapper {
    border-radius: 3px;
}

.dialysis-container .css-1s2u09g-control {
    border: 1px solid #CCCCCC;
    border-radius: 8px;
    height: 46px;
}

.dialysis-container .react-tel-input .form-control {
    border-radius: 8px;
}

.dialysis-container .react-tel-input .flag-dropdown,
.dialysis-container .react-tel-input .selected-flag {
    border-radius: 8px 0 0 8px;
}

.dialysis-container label span {
    color: red;
}

.relative-show {
    display: flex;
    flex-direction: column;
}

.relative-table {
    background-color: aliceblue;
    padding: 1%;
    margin-top: 1%;
}

.file-preview span {
    color: #666666 !important;
}

.error-label {
    color: red !important;
}

.error-border {
    border-color: red;
    background-color: rgba(255, 221, 221, 0.012);
}

.t-color {
    color: #FFFFFF !important;
    background-color: #094b84a0 !important;
}

.zero-padding {
    padding: 0 !important;
}

.consultants {
    background-color: #99989854;
    color: #000;
}

.strong-label {
    font-weight: 500;
}

.flex-dis {
    display: flex;
}


.label-margin label {
    margin: 0;
    font-size: 12px;
}

.data-dis p {
    margin: 0;
}

.data-dis-summary p {
    margin-left: 2%;
    text-align: left;
}

.prescription-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.prescription-table th,
.prescription-table td {
    border: 1px solid #ddd;
    padding: 5px 5px;
    text-align: left;
}

.prescription-table th {
    background-color: #4a4a4a;
    color: white;
}

.prescription-table td {
    padding: 10px;
}

.prescription-table .even-row {
    background-color: #f9f9f9;
}

.prescription-table .odd-row {
    background-color: #ffffff;
}

.prescription-table tr:hover {
    background-color: #e0e0e0;
}

.prescription-table {
    margin-top: 20px;
    font-family: Arial, sans-serif;
    font-size: 14px;
}

.discharge-head1 {
    margin: 0;
    align-content: end;
    font-size: 25px;
    font-weight: 600;
    text-align: center;
}

.discharge-head2 {
    margin: 0;
    font-size: 20px;
    text-align: center;
    text-decoration: underline;
    display: block;
}