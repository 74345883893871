.therapy-container {
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.therapy-head-container {
    display: flex;
    flex-direction: row;
    padding: 0 15px;
    align-items: center;
}

.therapy-head-container label {
    color: #004280;
    font-size: 22px;
    font-weight: 500;
    margin: 0;
}

.therapy-head-container button {
    color: #004280;
    background-color: #fff;
    border: 1px solid #004280;
    border-radius: 20px;
    font-size: 13px;
    font-weight: 400;
    height: fit-content;
    margin: 0 0 0 10px;
}

.therapy-body-container {
    display: flex;
    flex-direction: column;
    background-color: #e4dd9f46;
    border-radius: 5px;
    padding: 20px;
}

.therapy-input-container {
    display: flex;
    flex-direction: row;
}

.therapyinput-container {
    display: flex;
    flex-direction: column;
    height: fit-content;
    margin-bottom: 10px;
}

.therapyinput-container label {
    color: #666666;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    margin: 0;
    padding-left: 2px;
}

.therapy-input {
    background-color: #FFF;
    border: 1px solid #CCCCCC;
    border-radius: 4px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    font-size: 16px;
    font-weight: 500;
}

.therapy-button {
    border-radius: 20px;
    color: #fff;
    background-color: #004280;
    font-weight: 500;
    width: 100px;
    height: 35px;
    margin-top: 20px;
}

.therapy-slots {
    display: flex;
    direction: row;
}

.labels label {
    font-size: 18px;
    color: #004280;
}