.csc-primary-container .container .side-box1 {
    box-shadow: 0px 0px 20px #0000004f;
    border-radius: 10px;
    padding: 10px 15px;
    margin: 10px 30px;
}

.bookings {
    display: flex;
    flex-direction: column;
    background-color: #5587b521;
    padding: 30px;
    margin: 10px;
    border-radius: 25px;
}

.bookings-buttoncontainer {
    align-items: center;
    display: flex;
    border-radius: 15px;
    margin: 10px 0;
}

.bookings-buttoncontainer button {
    height: 40px;
    width: 150px;
    background-color: #fff;
    color: #2B69A3;
    border-radius: 40px;
    border: 1px solid #2B69A3;
    margin: 0 10px 0 0;
}

.bookingslist {
    display: flex;
    background-color: #fff;
    margin-top: 5px;
    margin-right: 2px;
    padding: 15px;
    border-radius: 8px;
}

.small-calendar {
    width: 100%;
    border: none;
    box-shadow: none;
}

.add {
    color: #1c94ac;
}

.side-box1 .react-calendar {
    border: none;
    box-shadow: none;
}

.side-box1 .react-calendar__navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
}

.side-box1 .react-calendar__navigation button {
    color: #666;
    background: none;
    border: none;
    font-size: 1.5em;
}

.side-box1 .react-calendar__navigation__label {
    color: #2B69A3;
    font-weight: bold;
    font-size: 1.2em;
}

.side-box1 .react-calendar__month-view__weekdays {
    font-weight: bold;
    color: #666;
    text-transform: uppercase;
}

.side-box1 .react-calendar__month-view__weekdays__weekday {
    font-weight: bold;
    color: #666;
}

.side-box1 .react-calendar__month-view__weekdays__weekday abbr {
    text-decoration: none;
}

.side-box1 .react-calendar__tile {
    color: #2B69A3;
    border-radius: 50%;
    border: none;
}

.side-box1 .react-calendar__tile--active {
    background-color: #1c94ac;
    color: white;
}

.side-box1 .react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    background-color: #1c94ac;
    color: white;
}

.side-box1 .react-calendar__navigation__label__labelText--from {
    font-size: 15px;
    display: flex;
    background-color: #fff;
}

.input-container-booking {
    display: flex;
    flex-direction: column;
    height: 78px;
    padding-left: 0;
}

.input-container-booking label {
    color: #000;
    margin: 0 0 0 5px;
    font-size: 14px;
    font-weight: 600px;
}

.cancel-butn {
    background-color: #ff260041;
    color: #FF0505;
    border-radius: 15px;
    width: fit-content;
    padding: 0 5px;
}

.confirm-butn {
    background-color: rgba(14, 145, 32, 0.267);
    color: rgb(14, 145, 32);
    border-radius: 15px;
    width: fit-content;
    padding: 0 5px;
}

.booklist-container {
    overflow-y: auto;
    max-height: 524px;
}

.booklist-container::-webkit-scrollbar {
    width: 5px;
}

.booklist-container::-webkit-scrollbar-track {
    background: #ffffff31;
}

.booklist-container::-webkit-scrollbar-thumb {
    background-color: #ffffff86;
    border-radius: 10px;
}

.booklist-container::-webkit-scrollbar-thumb:hover {
    background: #ffffffff;
    cursor: pointer;
}

.records-butn {
    background-color: #fff;
    border-color: #fff;
    color: blue;
}

.records-butn:hover {
    text-decoration: underline;
    cursor: pointer;
}