.roster-view {
    padding: 20px;
}

.roster-calendar {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.roster-table2 {
    border-collapse: collapse;
    width: 100%;
    text-align: center;
}

.roster-table2 th,
.roster-table2 td {
    border: 1px solid #ddd;
    padding: 10px;
    font-size: 14px;
}

.roster-table2 th {
    background-color: #f4f4f4;
    font-weight: bold;
}

.date {
    font-weight: bold;
    margin-bottom: 5px;
}

.statuss {
    border-radius: 50%;
    font-weight: 500;
    color: #fff;
    width: 25px;
    height: 25px;
    line-height: 20px;
    text-transform: capitalize;
    align-items: center;
    display: flex;
    justify-content: center;
    margin: auto;
}

.statuss.leave {
    background-color: red;
}

.statuss.off {
    background-color: orange;
}

.statuss.assigned-m {
    background-color: green;
}

.statuss.assigned-e {
    background-color: rgb(23, 23, 238);
}

.statuss.assigned-n {
    background-color: purple;
}

.statuss.empty {
    background-color: #ccc;
}