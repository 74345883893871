.doctor-card {

	border-radius: 5px;
	width: 300px;
	box-shadow: 4px 2px 10px rgba(0, 0, 0, 0.2);

	margin: 25px;
	margin-bottom: 40px;

	display: flex;
	flex-direction: column;
	align-items: center;
	font-weight: bold;
	color: var(--theme-card-dark-blue) !important;
	border: 1px solid #eeeeee;

}

.card-header12 {
	background-color: var(--theme-blue);
	width: 100%;
	color: rgb(255, 255, 255);
	padding-top: 15px;
}

.bdo-card-header {
	background-color: var(--theme-blue) !important;
}

.card-content12 {
	/* background-color: #ddc0c0; */
	background-color: #fff;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	align-items: center;
	padding-top: 15px;

}

.rounded-div {
	width: 130px;
	height: 130px;
	border-radius: 50%;
	border: 8px solid rgba(0, 0, 0, 0.2);
	margin-bottom: auto;

	background-color: #f0f0f0;
	text-align: center;
	display: flex;
	flex-direction: row;
	justify-content: center;
	color: #7952b3;
	margin-bottom: 10px;
	text-transform: capitalize;
}

.rounded-div h5 {
	margin: auto;


}

.doctor-card .avatar-pic {
	width: 150px;
	height: 150px;
	border-radius: 50%;
	vertical-align: middle;
	margin-bottom: 20px;

}

.dept {
	background-color: #f0f0f0;
	color: #7952b3;
	border-radius: 5px;
	padding: 10px;
}

.icons-bottom {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	margin-top: 20px;
}

.icons-bottom i {
	font-size: 18px;
	margin: 5px;

}

.icons-bottom i:hover {
	color: blue;
}

.contact-div {
	padding-top: 10px;
	border-top: 1px solid #fafafa;
	width: 100%;

}

.contact-div i {
	margin-right: 10px;
	color: #d3d3d3;
}

.dr-id {
	align-self: flex-start;


}



.red,
.green {
	width: 20px;
	height: 20px;
	border-radius: 50%;

	display: inline-block;
	margin-top: 5px;
}

.red {
	background-color: var(--red);


}

.green {
	background-color: var(--green);
}

/* .key-labels{
	color:var(--theme-card-dark-blue)!important;
	font-weight: bold;
} */

/* .bottom-links{
	border-radius: 0px 0px 20px 20px;

	padding-top: 10px;
	padding-bottom: 10px;
	margin-top: 35px;
	border-top: 1px solid #bdd7db;
	width:100%;
	color:white;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;


	background-color: #F0f0f0;
}
.bottom-links a{
	color: var(--theme-dark-blue);
	margin-right:20px;
	font-weight: bold;
} */
.card-user-details {
	display: flex;
	flex-direction: column;
	height: 100px;
	padding-left: 2%;
	overflow-y: auto;
	word-wrap: break-word;
	word-break: break-all;
	font-size: 12px;
}

.card-user-details>div {
	margin-top: auto;
}

.qualification-item {
	text-transform: capitalize;
	margin: 0;
}

.card-user-details::-webkit-scrollbar {
	width: 6px;
	cursor: pointer;
}

.card-user-details::-webkit-scrollbar-track {
	background: #FFFFFF;
}

.card-user-details::-webkit-scrollbar-thumb {
	background-color: #2457ae4f;
	border-radius: 10px;
}

.card-user-details::-webkit-scrollbar-thumb:hover {
	background: #2457ae73;
}



.round-head {
	border-radius: 50%;
	width: 30px;
	height: 30px;
	padding: 4%;
	padding-left: 6.5%;
	background-color: white;
	cursor: pointer;
}

.card-content h6 {
	color: var(--theme-card-dark-blue) !important;
	font-weight: bold;
}

.doctor-card .temp-disabled {
	color: grey !important;
	cursor: not-allowed !important;
}

.linethrough {
	text-decoration: line-through;
	text-decoration-color: var(--red);
	color: var(--red);
	font-weight: bold;

}

.card-content,
.doctor-card,
.doctor-card h6,
.bold-class,
.card-content span {
	font-weight: bold !important;
}