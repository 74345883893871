.club-table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
    margin-top: 20px;
}

.club-table th,
.club-table td {
    padding: 2px;
    border: 1px solid #ddd;
}

.club-table th {
    background-color: #f4f4f4;
    font-size: 12px;
    font-weight: bold;
}

.club-table .work {
    background-color: #d4edda;
}

.club-table .off {
    background-color: #f8d7da;
}

.club-table .leave {
    background-color: #ffeeba;
}