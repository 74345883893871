body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f9f9f9;
}

.app {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.header {
  text-align: center;
  margin-bottom: 20px;
}

.header h1 {
  font-size: 2.5em;
  color: #333;
}

.controls {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 20px;
}

.control-group {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.control-group label {
  font-weight: bold;
}

.control-group input,
.control-group select {
  padding: 5px;
  font-size: 1em;
}

.generate-button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.generate-button:hover {
  background-color: #0056b3;
}

.schedule {
  text-align: center;
}

.schedule h2 {
  margin-bottom: 10px;
}

.week-navigation {
  margin: 10px 0;
}

.week-navigation button {
  padding: 5px 10px;
  font-size: 1.2em;
  margin: 0 5px;
  cursor: pointer;
}


.off {
  background-color: #f8d7da;
  color: #721c24;
}

.work {
  background-color: #d4edda;
  color: #155724;
}

.roster-head {
  display: flex;
  justify-content: space-between;
}

.roster-head button,
.roster-button {
  background-color: #2456AE;
  color: white;
  height: fit-content;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  margin-top: auto;
}

.roster-button:hover {
  background-color: #265ab4;
  transform: scale(1.05);
}

.roster-head label {
  font-size: 24px;
  color: #2456AE;
  font-weight: 700;
}

.off {
  background-color: #ffcccc;
  text-align: center;
}

.work {
  background-color: #ccffcc;
  text-align: center;
}

.disabled {
  background-color: #f0f0f0;
  color: #bbb;
  text-align: center;
}

.addButton {
  width: fit-content;
  margin-left: auto;
  margin-bottom: 8px;
  background-color: #2456AE;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
}

.nurse-item {
  display: flex;
  justify-content: space-between;
  border: 1px solid #000;
  border-radius: 4px;
}

.removeButton {
  margin-left: 8px;
  background-color: transparent;
  border: none;
  color: red;
  cursor: pointer;
  font-size: 14px;
}

.no-data {
  color: red;
  margin: auto;
  font-size: 20px;
}

.roster-btn {
  padding: 10px 20px;
  background-color: rgb(36, 86, 174);
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
}