.change-pw-form {

	box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.3);
	padding: 30px;
	margin: auto;
}


.liner {
	width: 100%;
	background-color: #f0f0f0;
}

.user-edit-container {
	display: flex;
	justify-content: center;
	padding: 10px 0;
}