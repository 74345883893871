.butn-noti {
    background-color: #fff;
    color: #3c689e;
    border-radius: 5px;
    border: 1px solid #ccc;
    width: 100%;
    padding: 5px 0;
    margin: 2px;
}

.butn-noti-focused {
    background-color: #3c689e;
    color: #fff;
}

.cfa-scroll {
    overflow-y: auto;
    height: 300px;
}

.cfa-scroll::-webkit-scrollbar {
    width: 5px;
}

.cfa-scroll::-webkit-scrollbar-track {
    background: #fff;
    width: 10px;
}

.cfa-scroll::-webkit-scrollbar-thumb {
    background-color: #fffbed;
    border-radius: 10px;
}

.cfa-scroll::-webkit-scrollbar-thumb:hover {
    background: #fffbed;
    cursor: pointer;
}