.payment-display{
    background-color: #FFEDD4;
    padding: 20px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;
    width: 95%;
    margin-bottom: 30px;
}
.payment-search-grid{
    display: grid;
    grid-template-columns:0.5fr 1fr 0.4fr ;
    align-items: center;
    gap: 20px;
}

.payment-options{
    /* display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px; */
    display: flex;
    flex-direction: column;
    grid-gap: 15px;
}

.consult-payment-flex{
    display: flex;
    justify-content: flex-start;
    gap: 20px;
}

.option-display{
    background-color: #F5F5F5;
    border-radius: 5px;
    padding: 15px;
}
.consult-doctors{
    background-color: #fafafa;
    height: 40px !important;
}
.consult-grid{
    display: grid !important;
    grid-template-columns: 1.4fr 1fr !important;
    background-color: #FFFFFF;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 3px 3px #808080;
}
.consult-register-contain{
    background-color: #FFFFFF;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 3px 3px #808080;
}
.doc-detail-payment{
    background-color: #fafafa !important;
    height: 48px !important;
    overflow-x: auto;
}
.patient-detail-grid-view{
    display: grid;
    grid-template-columns: 1fr 3fr;
    padding: 10px;
    margin: 10px;
    /* text-align: left; */
    transition: all 0.30s ease;
    box-shadow: 1px 1px 5px rgb(0 0 0 / 30%) !important;
    background: white;
}

.patient-detail-grid-view:hover{
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.3) !important;
    cursor: pointer;
}

.hover-detail :focus{
    background-color: #C0C0C0;
    cursor: pointer;
}
.selected-patient{
    background-color: var(--theme-blue) !important;
}

.patient-detail-grid-view .each-list-link{
    align-self: center;
    text-align: left;
    color: #6c757d !important;
    font-weight: 600;
    text-transform: capitalize;   
}

.patient-detail-grid-view .details{
    display: grid;
    grid-template-columns: 1fr 1fr 1.5fr;
}

.patient-detail-grid-view .details span{
    background-color: #a4a777;
    border-radius: 20px;
    padding: 5px 10px;
    color: white;
    font-weight: bold;
    align-self: center !important;
    justify-self: center !important;
}

.patient-detail-grid-view .details p{
    background-color: var(--theme-blue);
    border-radius: 20px;
    padding: 5px 10px;
    color: white;
    font-weight: bold;
    align-self: center !important;
    justify-self: center !important;
}
.past-payment-name{

    color: var(--theme-blue);
    font-weight: bold;
}
.boxsidebar{
    box-sizing: border-box;
padding:1%;
/* position: absolute; */
/* width: 140px;
height: 29px; */
/* margin-left: -32%; */
color:white;
background: #245693;
border: 1px solid #FFFFFF;
border-radius: 10px 0px;
/* align-items:center;
justify-self: center !important; */
}
.pad-payment{
    padding: 15px;
}

.text-center123{
    padding: 15px;
    justify-content: center;

}
.doctor-card45{

    border-radius: 5px;
    width:200px;
    box-shadow: 4px 2px 10px rgba(0,0,0,0.2);
    
    /* margin:25px; */
    /* margin-bottom: 50px; */
    
    /* display: flex; */
    /* flex-direction: column;
    align-items: center; */
    font-weight: bold;
    color:var(--theme-card-dark-blue)!important;
    border:1px solid #eeeeee;
    
    }

    .drs-container-not1 {
        display: flex;
        flex-direction: row !important;
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 30px;
        height: 500px;
        overflow-x: scroll;
    }
.payment-page{
    background-color: whitesmoke;
    box-shadow:0 5px 5px#696969;
    padding: 10px;
    border-radius: 8px;
}
.selected-display-payment{
    display: inline-flex;
    justify-content: center;
    background-color: var(--theme-blue);
    padding: 8px;
    font-weight: bold;
    border-radius: 8px;
    color: white;
}

/* .carousel{
    height:100%
} */


.carousel{
    overflow: hidden;
    position: relative;
   
}

.carousel-images{
    position:absolute;
    top:0;
    left:0;
    z-index:1;
    display:flex;
    width:300%;
    height:100%;
    transition: 0.5s;
}

/* .carousel-images .boxsidebar .text-center123 {
    width:100%;
    object-fit: cover;


} */

.carousel-controls{
    height:100%;
    justify-content: flex-end;
}

.carousel-controls, .dots{
   
    z-index: 2;
    display:block;
    width:200px;
    height:200px;
    border-radius: 10%;
    background: rgba(255,255,255,0.5);
    transition:0.3s;
}

.dots{
    left:0;
    bottom:0;
    height:400px;
    background: linear-gradient(transparent,#000 90%);
    gap:12px;
    justify-content: center;
    padding-bottom: 50%;
}

input:nth-child(1):checked ~ 
.dots label:nth-child(1)

input:nth-child(2):checked ~
.dots label:nth-child(2)
{
    background: rgba(219, 12, 12, 0.873);
}

input:nth-child(1):checked ~ .carousel-images{
    translate: 0;
}
input:nth-child(2):checked ~ .carousel-images{
    translate:-200vw;
}

/* .paymentconfigouter{
   position: relative; 
width: 900px;
height: 100px;
margin-left: -1%;
margin-top: -1.5%;
background: linear-gradient(180deg, #F5F5F5 0%, #DBFDF1 50%, #F9F9F9 100%);
border-radius: 10px;
} */

.paymentconfigfont{


    
    
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    align-items:center;
    justify-content: center;
    color: #316ec9;
}


  .paymentconfigfont .nav-link {
    color: #086073;/* inactive tab color */
  }
  
  .paymentconfigfont .nav-link.active {
    color: #080000; /* active tab color */
    background-color: #007bff; /* active tab background color */
    border-color: #007bff; /* active tab border color */
  }
  
  




.list-medicines{

    box-sizing: border-box;


width: 645px;
height: 60px;
align-items: center;
margin-left: 10%;
background: #FFFFFF;
border: 1px solid #ADADAD;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 10px;
}
.list-names{
    width:300px;
height: 29px;


font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 29px;
/* identical to box height */


color: #000000;
}

.paymentconfiginner{

    /* position: absolute; */
    /* width: 900px; */
    height:fit-content;
overflow-x: auto;
background: #FFFFFF;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 10px;


}

.medicinebuttons1{

    /* position: absolute; */
width: 150px;
height: 41px;
margin-left: 30%;
margin-top: 3%;
background: #086073;
border-radius: 10px;
color:#FFFFFF
}
.medicinebuttons2{

    /* position: absolute; */
    width: 100px;
    height: 41px;
    margin-left:2%;
    margin-top: 3%;
    color:#FFFFFF;
    background: #8F8F8F;
    border-radius: 10px;
}
.checkbox-container {
    display: flex;
    align-items: center;
 
  }
  
  .checkbox-input {
    margin-right: 20px;
    margin-left: 20px;

  }





.adjust-dimensions{
    width: 87%;
    height: 140px;
}
.detail-input-grid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
}

@media only screen and (min-width: 800px) {
    .config-modal-width {
      max-width: 700px;
      
    
       /* New width for default modal */
    }
    .config-modal-width {
      max-width: 1000px;
   
       }
  }

  @media print {
    input[type="radio"]:checked + label {
      background-color: #e4dd9f;
      color: black;
      font-weight: bold;
      padding: 2px 5px;
      border: 1px solid black;
      border-radius: 4px;
    }
  
    input[type="radio"] {
      display: none; /* Hide the actual radio button in print */
    }
  }
  
  