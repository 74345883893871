.footerclass {

	background: #009996;
    color: #fff;
    font-weight: 700;
    padding-top: 30px;
    width: 100%;
	margin-top: auto;
	z-index: 1;
}
	.align-content{

	align-items: flex-end;

	}
	
	
	
	h6.text-muted1 {
		color: #f8f8fa !important;
		text-size-adjust:10px;
		
	}

	h6.text-muted {
		color: #e0e0e0 !important;
		
	}
	.flex-column-footer{
		display:flex;
		flex-direction:column;
		flex: 1;
		justify-content: flex-start;
		align-items: flex-start;
		color: rgb(251, 251, 251);
		
		
	}
	.footer-row{
		display:flex;
		flex-direction:row;
		/* justify-content: space-between !important; */
		padding-left:30px;
		padding-right:30px;
		font-weight: bold;
	}
	.footer-title{
	
		text-decoration: underline;
	}
	footer a{
		color:white!important;
	}
	footer a:hover{
	 color:black;
	}
	
	.mob2{
		
	margin-left:2px;
	}
	
	.social-media-icons{
		color:white;
		font-size:32px;
		margin-right:15px;
		margin-left:15px;
	}
	.social-media-icons:hover{
		color:var(--theme-icons-grey);
	}
	.version-info{
		align-self: flex-end;
		justify-content: flex-end;
	}
	.flex-version-end{
		display:flex;
		justify-content: flex-end;
		margin-right:50px;
	
	}
	.divider-line{
		height:1px;
		background-color:grey;
		width:80vw;
		justify-content: center;
		margin:auto;
		margin-top:40px;
		margin-bottom:30px;
	}
	
	.divider-lineSection{
		height:1px;
		background-color:grey;
		width:10vw;
		justify-content: center;
	}
	
	.copy-info{
		margin-top:20px;
	}
	.logo-footer{
		height:32px;
	
	}
	.logo-footer-url{
		margin-bottom:20px;
	}
	.google-play{
		height:60px;
		width:auto;
		margin-left: 15px;
		margin-right:10px;
	}
	.app-store{
		display: inline-block;
		overflow: hidden; 
	
		width: auto; 
		height: 42px;
	}
	.app-store2{
	
		width: auto;
		height: 42px;
	}
	.footer-icons-row{
		/* align-items:center; */
	}
	.logo-footer-2{
		margin-left: 15px;
		margin-right:15px;
		
	}

	@media only screen and (max-width: 468px)  {
		.footer-row{
			display:flex;
			flex-direction:column;
			justify-content: space-between !important;
			padding-left:30px;
			padding-right:30px;
		
		}
	}