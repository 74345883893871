.dialysishome-container {
    padding: 20px;
    box-sizing: border-box;
}

.side-tabs {
    margin: 5px 0 0 0;
    border: .5px solid #666666;
    height: fit-content;
    border-radius: 15px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

.side-button {
    height: 40px;
    background-color: #808080;
    color: #FFFFFF;
    border-radius: 10px;
    margin-bottom: 5px;
}

.selected-button {
    height: 40px;
    background-color: #2B69A3;
    color: #FFFFFF;
    border-radius: 10px;
    margin-bottom: 5px;
}

.application-contents {
    box-sizing: border-box;
}

.contents {
    height: 110vh;
    border-radius: 15px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    margin-top: 3px;
}

.container-height {
    height: fit-content;
}

.head {
    display: flex;
    width: 95%;
    padding: 0 .8rem 0 4rem;
    text-align: center;
    justify-content: center;
}

.contents-head {
    font-size: 18px;
    color: #2456AE;
    font-weight: 600;
}

.content {
    padding: 0 .3rem 0 4rem;
    width: 95%;
    overflow-y: auto;
}

.content::-webkit-scrollbar {
    width: 6px;
}

.content::-webkit-scrollbar-track {
    background: #FFFFFF;
}

.content::-webkit-scrollbar-thumb {
    background-color: #2457ae36;
    border-radius: 10px;
}

.content::-webkit-scrollbar-thumb:hover {
    background: #2457ae65;
}

.queue-container1::-webkit-scrollbar {
    width: 6px;
}

.queue-container1::-webkit-scrollbar-track {
    padding: 1px;
    background: #FFFFFF;
}

.queue-container1::-webkit-scrollbar-thumb {
    background-color: #e4dd9f70;
    border-radius: 10px;
}

.queue-container1::-webkit-scrollbar-thumb:hover {
    background: #e4dd9fa2;
}

.queue-content2::-webkit-scrollbar {
    width: 6px;
}

.queue-content2::-webkit-scrollbar-track {
    background: #FFFFFF;
}

.queue-content2::-webkit-scrollbar-thumb {
    background-color:#2457ae36;
    border-radius: 10px;
}

.queue-content2::-webkit-scrollbar-thumb:hover {
    background:  #2457ae6d;
}

.queue-content1::-webkit-scrollbar {
    width: 6px;
}

.queue-content1::-webkit-scrollbar-track {
    background: #FFFFFF;
}

.queue-content1::-webkit-scrollbar-thumb {
    background-color: #4ab6443f;
    border-radius: 10px;
}

.queue-content1::-webkit-scrollbar-thumb:hover {
    background: #4ab64475;
}

.dename-content {
    font-size: 18px;
    text-transform: capitalize;
    align-content: center;
}

.eye-content {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
}

.rejected {
    display: flex;
    align-items: center;
    font-weight: 600;
}

.rejected h6 {
    margin: auto;
    font-size: 12px;
    font-family: inter;
    font-style: italic;
    color: red;
}

.dialysisreq_lists {
    border: .5px solid #666666;
    border-radius: 10px;
    margin-bottom: 6px;
    height: 75px;
    display: flex;
    justify-content: center;
    align-content: center;
    padding: 0;
}

.icon-background {
    border-radius: 50%;
    background-color: #E4DD9F;
    height: 40px;
    width: 40px;
    align-content: center;
    text-align: center;
    color: #085eaf;
}

.icon-col {
    justify-content: center;
    align-items: center;
    display: flex;
}

.icon-item {
    height: 20px;
    width: 20px;
    cursor: pointer;
}

.save-button {
    background-color: #2456AE;
    width: 70px;
    height: 30px;
    border-radius: 10px;
    color: #FFFFFF;
    font-size: 11px;
    font-weight: 400;
    align-self: center;
    margin: auto;
}

.inhouse-save {
    background-color: #2456AE;
    width: fit-content;
    height: 30px;
    border-radius: 10px;
    color: #FFFFFF;
    font-size: 11px;
    font-weight: 400;
    padding: 0 20px;
}

.save-col {
    justify-content: center;
    align-items: center;
    display: flex;
}

.queue-container {
    border: .5px solid #808080;
    border-radius: 5px;
    height: 90vh;
    padding: 3px;
    margin: 36px 50px 1vh 50px;
    display: flex;
    flex-direction: column;
}

.queue-head {
    height: 60px;
    background-color: #2B69A3;
    opacity: 81%;
    width: 100%;
    margin: auto !important;
    border-radius: 3px;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.queue-head1 {
    align-items: center;
    justify-content: center;
    display: flex;
    font-weight: 500;
    font-size: 20px;
    line-height: 19.2px;
    color: #FFFFFF;
    border-right: .5px solid #FFFFFF;
    position: relative;
}

.queue-head1 svg,
.queue-head2 svg {
    position: absolute;
    right: 10px;
    cursor: pointer;
}

.queue-head2 {
    align-items: center;
    justify-content: center;
    display: flex;
    font-weight: 500;
    font-size: 20px;
    line-height: 19.2px;
    color: #FFFFFF;
    position: relative;
}

.queue-content {
    height: 70vh;
    width: 100%;
    margin: auto !important;
    margin-top: 0;
    margin-bottom: 0;
}

.queue-content-grid {
    display: flex;
    flex-wrap: wrap;
}

.grid-view {
    box-sizing: border-box;
    width: 49.7% !important;
    margin: 1px;
}

.queue-content-trust {
    height: 70%;
    width: 100%;
    margin: auto !important;
    margin-top: 0 !important;
    margin-bottom: .5% !important;
    padding: 2px 0 0 0;
    margin-right: -1px !important;
}

.queue-content1 {
    border-right: .5px solid #808080b5;
    height: 100%;
    padding: 0 !important;
    margin: auto !important;
    overflow-y: auto;
}

.queue-content3 {
    /* height: 100%; */
    padding: 0 !important;
    margin: auto !important;
    margin-top: 0 !important;
}

.queue-content2 {
    padding: 0 !important;
    height: 100%;
    margin: auto !important;
    overflow-y: auto;
}

.queue-content3 .color {
    background-color: #e4dd9f70;
    border: none;

}

.dialysis_priorityqueue {
    background-color: #4ab64428;
    border: .5px solid #4ab6448a;
    border-radius: 3px;
    margin-bottom: 2px;
    min-height: 50px;
    display: flex;
    justify-content: center;
    align-content: center;
}

.dialysis_pendingqueue {
    background-color: #2b69a328;
    border: .5px solid #2b69a38a;
    border-radius: 3px;
    margin-bottom: 2px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-content: center;
}

.eye-content-div {
    border: 1px solid #66666684;
    border-radius: 5px;
    width: 70%;
    margin: auto !important;
    height: 35px;
    background-color: #FFFFFF;
    color: rgba(36, 86, 147, 1);
}

.envelop {
    padding: 0;
    align-content: center;
    height: 100%;
}

.deny {
    padding: 0;
    align-content: center;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    cursor: pointer;
}

.eye {
    padding: 0;
    text-align: center;
    height: 100%;
    align-content: center;
    cursor: pointer;
}

.queue-button {
    width: 100%;
    margin: auto;
}

.queue-button .save {
    background-color: #2B69A3;
    width: 160px;
    height: 35px;
    border-radius: 25px;
    color: #FFFFFF;
    font-size: 15px;
    font-weight: 300;
    align-self: center;
    margin: auto;
}

.queue-button .submit {
    background-color: #2B69A3;
    width: 120px;
    height: 28px;
    border-radius: 25px;
    color: #FFFFFF;
    font-size: 15px;
    font-weight: 300;
    align-self: center;
    margin: auto;
}

.nil {
    font-size: 18px;
    color: #666666;
}

.queue-container1 {
    border: .5px solid #808080;
    border-radius: 5px;
    height: 50vh;
    padding: 3px 2px 3px 3px;
    margin: 0 50px 1vh 50px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.queue-container2 {
    border: .5px solid #808080;
    border-radius: 5px;
    height: 51vh;
    padding: 3px;
    margin: 0px 50px;
    display: flex;
    flex-direction: column;

}


.dialysis-request-heads {
    padding: 0;
    text-align: center;
}

.fa-stack {
    align-content: center !important;
    display: flex !important;
    width: 100% !important;
    height: 100% !important;
    margin: auto !important;
    justify-content: center;
    flex-wrap: wrap;
}

.fa-eye:before {
    content: "\f06e";
    display: flex;
    justify-content: center;
}

.tool-tip {
    background-color: #666666;
    color: #04040466;
}

.status-render {
    display: flex;
    align-items: center;
    justify-content: center;
}

.status-render label {
    margin: 0;
    font-size: 14px;
    font-weight: 700;
}

.nodata {
    margin-top: 50px;
    color: #FF0000;
    display: flex;
    justify-content: center;
}

.dialysis_inhouse {
    height: 60px;
}

.inhouse-label {
    color: #2456AE;
    padding: 0;
    font-size: 18px;
}

.selection {
    color: rgb(43, 105, 163);
    display: flex;
    justify-content: center;
    align-items: center;
}

.queue-badge {
    margin-top: -5px;
    flex-wrap: wrap;
    align-content: center;
    font-size: 12px;
    display: flex;
    justify-content: center;
    border-radius: 50%;
    background-color: red;
    height: 15px;
    width: 15px;
}

.denialview {
    margin-right: 1px !important;
    margin-bottom: 10px !important;
}