.mschedule-primary-container {
    margin: 3.5% 10%;
    padding: 2%;
    box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
}

.mschedule-modal-content {
    width: 100%;
    box-sizing: border-box;
}

.mschedule-head {
    text-align: center;
}

.mschedule-head label {
    color: #2456AE;
    font-weight: 500;
    font-size: 20px;
    margin: 20px;
}

.mschedule-daycontainer {
    border-radius: 5px;
    background-color: #e4dd9f7c;
    padding: 20px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
}

.mschedule-machinecontainer {
    height: 30vh;
    border-radius: 5px;
    background-color: #e4dd9f7c;
    padding: 10px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.day-checkbox {
    box-sizing: border-box;
    padding: 5px;
}

.mschedule-inputcontainer,
.mschedule-date-inputcontainer {
    border-radius: 5px;
    background-color: #e4dd9f7c;
    padding: 20px;
}

.mschedule-inputcontainer {
    display: flex;
    flex-direction: column;
}

.mschedule-date-inputcontainer {
    display: flex;
}

.msinput-container {
    display: flex;
    flex-direction: column;
    height: fit-content;
    margin-bottom: 10px;
}

.msinput-container label {
    color: #666666;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    margin: 0;
    padding-left: 2px;
}

.msinput1 {
    background-color: #FFF;
    border: 1px solid #CCCCCC;
    border-radius: 4px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.schedule-set {
    display: flex;
    justify-content: space-between;
    width: 100%;
}


.dialysis-icon3 {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color: #2B69A3;
    border: 1px solid #E4DD9F;
    margin: auto;
}

.dialysis-icon4 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-color: #2B69A3;
    border: 1px solid #E4DD9F;
    position: relative;
}

.riErrorWarningFillIcon {
    position: absolute;
    top: -6px;
    left: -6px;
    color: red;
    background-color: white;
    border-radius: 50%;
    padding: 2px;
    font-size: 20px;
}



.mschedule-card {
    width: 100%;
    border: 1px solid #6666667a;
    border-radius: 8px;
    display: flex;
    background-color: #FFF;
    margin-bottom: 1%;
    padding: 10px;
}

.mschedule-card .model-name,
.mschedule-card .unit-no,
.mschedule-card .machine-status {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    margin: 0;
    padding: 0;
    text-align: center;
    align-content: center;
}

.mschedule-card .model-name {
    color: #121111;
    flex: 4;
    font-weight: 400;
    font-size: 15px;
    margin: 0;
}

.mschedule-card .unit-no {
    color: #666666;
    flex: 3
}

.mschedule-card .machine-status {
    flex: 2;
    color: #3DB642;
}

.schedule-button {
    background-color: #2B69A3;
    color: #FFF;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    align-content: center;
    text-align: center;
    display: grid;
    padding: 0;
    cursor: pointer;
    margin-top: -15px;
    margin-left: auto;
    margin-right: -15px;
}

.mschedule-button-container {
    display: flex;
    margin: 20px 10px 0 0;
}

.mschedule-button-container .done-button {
    width: 100px;
}

.day-view {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    border-radius: 50%;
    margin: 5px;
    font-weight: bold;
    color: white;
    height: 35px;
}

.day-view.green {
    background-color: #3DB642;
}

.day-view.grey {
    background-color: #D9D9D9;
}

.mschedule-date {
    flex: 1;
    display: flex;
    justify-content: center;
    font-size: 13px;
    font-weight: 400;
}

.mschedule-view-icons {
    flex: 2;
    display: flex;
    justify-content: flex-end;
}

.mschedule-time {
    flex: 4;
    text-align: center;
    display: flex;
    flex-direction: column;
}

.error-border {
    border-color: red;
}

.mschedule-dayview-container {
    flex: 4;
    display: flex;
    flex-direction: column;
}

.mschedule-ti {
    display: flex;
    flex: 1 1 0%;
    align-content: center;
    flex-wrap: wrap;
    justify-content: center;
}

.disabled-cursor {
    cursor: not-allowed;
}

.pointer {
    cursor: pointer;
}