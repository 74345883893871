.search-form .search-div {
  margin: auto;
  text-align: center;
}

.title-of-tasks {
  text-align: center;
  color: #666666;
  text-transform: capitalize !important;
  font-weight: bolder;
}

.search-section {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.search-section .search-input {
  width: 300px !important;
}

.search-btn {
  height: 44px !important;
  border-radius: 0px 4px 4px 0px !important;
}

.list-item .each-list-link,
.list-item h5 {
  color: white;
  margin-right: 10px;
}

.list-item .each-list-link,
.list-item a {
  color: #6c757d;
  font-weight: 600;
  text-transform: capitalize;
}

.search-patient-page .list-item {
  padding: 10px;

  border-radius: 5px;
  margin: 10px;
  display: flex;
  flex-direction: row !important;
  flex-wrap: nowrap;
  justify-content: space-between;
  text-align: left;
  transition: all 0.3s ease;
  box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.3);
}

.list-item:hover {
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
}

.list-item .details {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.search-patient-page .list-item .details p {
  margin-right: 20px;
  background-color: #33B5E5;
  border-radius: 20px;
  padding: 5px 10px;
  color: white;
  font-weight: bold;
}

.search-patient-page .list-item {
  width: 70%;
  margin: 10px auto;
  text-decoration: none;

}

.search-patient-page h5 {
  text-decoration: none;
}

.search-patient-page .list-item:hover {
  background-color: var(--theme-blue);
  box-shadow: 0 4px 4px var(--theme-blue);
}

.search-patient-page h5:hover {
  color: #fff !important;
}

.search-results {
  margin: 30px auto;
}

.search-patient-page a:hover {
  text-decoration: none;
  color: #fff !important;
}

#buttons li {

  float: left;
  list-style: none;
  text-align: right;
  background-color: yellowgreen;
  margin-right: 30px;
  width: 150px;
  line-height: 60px;
}

#buttons li a {
  text-decoration: none;
  color: #FFFFFF;
  display: block;
}

#buttons li a:hover {
  text-decoration: none;
  color: #000000;
  background-color: #33B5E5;
}

.btn-letter {
  width: 30px !important;
  margin: 2px !important;
}

.directory-page {
  margin-top: 10px;
}

.scroll-class {
  position: fixed;
  top: 600px;

}

.search-patient-page .list-item .details span {
  margin-right: 20px;
  background-color: #a4a777;
  border-radius: 20px;
  padding: 5px 10px;
  color: white;
  font-weight: bold;
  align-self: flex-start;
}

.list-item-new {
  border: 1px solid rgba(164, 164, 164, 1);
  margin-top: 1%;
  border-radius: 5px;
  box-sizing: border-box;
}

.list-item-new .row {
  margin-left: 0 !important;
  box-sizing: border-box;
}

.each-list-link1 {
  text-transform: capitalize;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(97, 97, 97, 1);

}

.each-list-link2 {
  color: rgb(119, 118, 118);
  font-family: Poppins;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;

}

.doc-right {
  border: 1px solid rgba(206, 206, 206, 1);


  border-radius: 5px
}

.dir-button button {
  padding: 0 !important;
  margin: auto;
}

.dir-button :hover {
  font-size: 27px;
  box-shadow: 0px 4px 4px rgba(43, 37, 211, 0.25);
  background-color: rgba(43, 37, 211, 0.25)
}

.listofrep {
  border: 1.5px solid rgba(0, 0, 0, 1);

  border-radius: 5px;


}
@keyframes slideIn {
  0% {
    width: 0%;
  }

  100% {
    width: 33.33333333%;
  }
}

@keyframes slideOut {
  0% {
    width: 33.33333333%;
  }

  100% {
    width: 0%;
  }
}
@keyframes widthIn {
  0% {
    width: 80%;
  }
  100% {
    width: 50%; 
  }
}

@keyframes widthOut {
  0% {
    width: 50%; 
  }
  100% {
    width:80%;
  }
}

.shrink-in {
  animation: widthIn 0.5s forwards;
}

.shrink-out {
  animation: widthOut 0.5s forwards;
}

.slide-in {
  animation: slideIn 0.5s forwards;
}

.slide-out {
  animation: slideOut 0.5s forwards;
}

/* @keyframes slideIn {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes slideOut {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(100%);
  }
} */

