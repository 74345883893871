.leave-request {
  display: flex;
  justify-content: center;
  padding: 20px;  
  gap: 20px;
}

.leave-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border-radius: 10px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 1200px;
  text-align: center;
  font-size: 1.5rem;
}

.leave-header.active {
  background-color: #0056b3;
}

.add-icon {
  cursor: pointer;
  font-size: 1.5rem;
  transition: transform 0.5s;
}

.add-icon:hover {
  transform: scale(1.1);
}

.content-header {
  padding: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  background-color: #f9f9f9;
  color: #2b69a3;
}


.content-container {
  display: flex;
  width: 100%;
  /* max-width: 1200px; */
  justify-content: space-between;
  gap: 20px;
}

.leave-list {
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.applications {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  align-items: center;
}

.application {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 15px 20px;
  border-radius: 10px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  /* max-width: 600px; */
}

.application .type {
  margin: 0;
  font-weight: 600;
  color: #333;
}

.application .date {
  font-size: 0.9rem;
  color: #555;
  margin: 0;
}

.application .status {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 0.9rem;
  padding: 1px;
  border-radius: 5px;
  text-align: center;
}

.application .bttn {
  display: flex;
  justify-content: space-between;
  margin: 10px 0 0 0;
  align-items: end;
}

.application .bttn label {
  margin: 0;
}

.application .bttn button {
  padding: 3px 10px;
  border-radius: 5px;
  background-color: #707070;
  color: #fff;
}

.application.awaiting .status {
  background-color: #ffc107;
  color: #fff;
}

.application.approved .status {
  background-color: #28a745;
  color: #fff;
}

.application.declined .status {
  background-color: #dc3545;
  color: #fff;
}

.application .category {
  margin-top: 5px;
  font-size: 0.9rem;
  color: #6c757d;
}

.add-leave-form {
  flex: 2;
  background-color: #fff;
  padding: 20px;
  box-shadow: -2px 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  position: relative;
}

.add-leave-form h2 {
  margin-bottom: 20px;
  font-size: 1.5rem;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.add-leave-form .form-group {
  margin-bottom: 15px;
}

.add-leave-form input,
.add-leave-form select,
.add-leave-form textarea {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.add-leave-form button {
  width: 100%;
  background-color: #007bff;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-leave-form button:hover {
  background-color: #0056b3;
}

.content-container {
  flex: 3;
}

.content-container {
  width: 100%;
  box-sizing: border-box;
  border-radius: 10px;
}