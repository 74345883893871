@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&family=Source+Sans+Pro:wght@400;600&display=swap');

:root{
   --theme-blue : #2b69a3;
   --dark-teal : #00657c;
   --dark-blue : #173e81;
   --theme-dark : #1f1714;
   --theme-gold : #a4a777;
   --theme-purple : #5c2572;
   --theme-grey : #0000a6;
   --theme-dark-blue : #1593a7;
   --theme-icons-purple : #8c3c8d;
   --theme-icons-dark-blue: #404191;
   --theme-icons-grey:#d5d5ea;
   --theme-icons-light-purple : #852ea0;
   --theme-card-dark-blue: #254466;
   --theme-lightest-grey : #f8f9fa;
   --theme-box-shadow: 4px 10px 18px rgba(0,0,0,0.3);
   --theme-blue-variant:#2f828f;
   --flat-red: rgba(255, 99, 132, 1)!important;
    --flat-yellow:rgba(255, 206, 86, 1) !important;
    --flat-blue: rgba(54, 162, 235, 1) !important;
    --flat-green:var(--teal) !important;
   --theme-light-teal:#37a8a8;
   --theme-navbar:#e8e1e8;
   --theme-darker-blue:#207171;
   --theme-light-blue:#bdd7db;
   --theme-secondary: #9ADFCC
}
@font-face {
  font-family: Roboto,sans-serif;
  font-display: swap; 
  font-style: normal;
  font-weight: 400;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.btn, .btn-primary btn-col,.btn-secondary, .btn-danger, .ibtn, .btn-custom{
  border-radius: 15rem !important;
  box-shadow: 4px 4px 10px rgba(0,0,0,0.3);
  padding:6px 20px!important;
  font-size:17px !important;
}

.btn-color {
  background-color: var(--theme-blue);
  color: white !important;
}

.btn-small{
   padding:5px 10px!important;
    border-radius: 15px !important;
}

.btn-smallest{
 padding:3px 8px !important;
 font-size:10px !important;
 border-radius: 20px !important;
}

.btn-square{
  border-radius:4px !important;
}

.btn-col{
  background-color: var(--dark-blue) !important;
  color: white;
  border-color: var(--dark-blue) !important;
  font-size:17px !important;
}

.btn-col:hover{
  background-color: var(--dark-blue) !important;
  border-color: var(--dark-blue) !important;
}

.btn-print{
  background-color: #218838 !important;
  color: white;
  border-color: #1e7e34 !important;
  font-size:17px !important;
}

.btn-print:hover{
  background-color: #1e7e34 !important;
  border-color: #1e7e34 !important;
}

.btn-cancel{
	margin-right:10px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.title-of-page{
  text-align: center;
  color: #1f1714 !important;
  text-transform: capitalize !important;
  font-weight: bolder;
  margin-top: 20px;
  margin-bottom: 20px;
}
.subtitle-of-page{
	text-align: center;
    color: #666666 !important;
    text-transform: capitalize !important;
    font-weight: bolder;
   
}
.form-button{
  text-align: center;
}
.card-margin{
  margin-top:20px;
}
.dashboard-navigation {
  margin-top: 40px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.dashboard-navigation a {
  margin-bottom: 15px;
}

.sda-dashboard{
  color: #aeaeae !important;
  text-transform: none !important;
}

.home {
  /* min-height: 70vh; */
}