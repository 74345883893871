.dis {
    display: flex;
}

.dir {
    flex-direction: column;
}

.leave-request-view {
    display: flex;
    flex-direction: column;
    padding: 10px;
    background-color: #fff;
    border-radius: 10px;
    margin-bottom: 5px;
}

.leave-request-view label {
    margin: 0;
}

.leave-request-view-head {
    font-weight: 600;
}

.leave-request-view-dates {
    font-size: 14px;
    font-weight: 600;
    color: #767676;
}

.roster-profile {
    padding: 0;
    justify-content: center;
    align-items: center;
}

.approve-button {
    /* width: 30px; */
    width: fit-content;
    height: 30px;
    /* border-radius: 50px; */
    border-radius: 5px;
    padding: 15px 2px;
    cursor: pointer;
    background-color: #329839;
    color: #fff;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 14px;
}


.reject-button {
    background-color: #585757;
}