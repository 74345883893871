.add-dr-form-holder {
	margin-bottom: 100px;
}


.adduser-form {
	box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.2);
	padding: 20px;
	border-radius: 5px;
	width: 60%;
	margin: auto;
}

.add-dr-form .form-control {
	width: 100% !important;
}

.form-div {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-around;
}

.add-dr-div {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin-bottom: 30px;
}

.add-dr-form .form-group {
	text-align: left;
	margin-bottom: 20px !important;
}

.title-add-dr {
	margin-top: 30px;
	margin-bottom: 50px;
}

/* from ioform-style.css */
.add-dr-form div.country-selector-dr select,
.add-dr-form div.region-selector-dr select,
.add-dr-page .dob-div {
	width: 100%;
	padding: 9px 20px;
	text-align: left;
	border: 0;
	outline: 0;
	border-radius: 6px;
	/* background-color: #fff;*/
	font-size: 15px;
	font-weight: 300;
	color: #8d8d8d;
	-webkit-transition: all 0.3s ease;
	transition: all 0.3s ease;
	margin-bottom: 14px;
	border: 1px solid #ced4da !important;
	height: 44px;
}

.add-dr-form div.country-selector-dr select:hover,
.add-dr-form div.region-selector-dr select:hover {
	border: 0;
	/*background-color: #ebeff8;*/
	color: #8d8d8d;
}

/*from iofprm-theme4.css */
.add-dr-form div.country-selector-dr select,
.add-dr-form div.region-selector-dr select {
	border: 1px solid rgba(0, 149, 255, 0);
	/* background-color: #f7f7f7; */
	color: #000;
}

.add-dr-form div.country-selector-dr select:hover,
.add-dr-form div.region-selector-dr select:hover {
	border: 1px solid #0093ff;
	/* background-color: #fff; */
	color: #000;
}

.liner {
	width: 100%;

	background-color: #f0f0f0;
}

.modal-body h4 {
	color: var(--green);
}

.centralize {
	display: flex;
	flex-direction: column;
	justify-content: center;
}