.csc-primary-container {
    display: flex;
}

.csc-primary-container .container {
    display: flex;
    flex-direction: column;
    padding: 0;
}

.csc-primary-container .container .side-box {
    box-shadow: 3px 5px 5px #00000039;
    border: .5px solid #969696;
    border-radius: 10px;
    height: fit-content;
    padding: 10px 15px;
    margin: 10px;
}

.sidebox-search-input-container {
    background-color: #5587b563;
    border-radius: 27px;
    display: flex;
    border: none;
    width: 100%;
    height: 40px;
    align-content: center;
    flex-wrap: wrap;
    justify-content: center;
}

.sidebox-search-input {
    width: 80%;
    background-color: none;
    border: none;
    font-size: 14px;
    font-weight: 500;
    padding: 5px;
    background-color: #5587b500;
    border-radius: 27px;
}

.side-box-head {
    text-align: center;
    margin-bottom: 5px;
}

.side-box-head label {
    font-weight: 500;
    color: #2B69A3;
    font-size: 20px;
}

.csc-doctorlist {
    cursor: pointer;
    margin: 3px 2px;
    height: fit-content;
    background-color: #e4dd9f7e;
    border-radius: 5px;
    display: flex;
    padding: 10px;
    justify-content: center;
    align-content: center;
}

.csc-slotlist {
    display: flex;
    margin: 3px 0;
    height: fit-content;
    border-radius: 5px;
    border: 1px solid #000;
    padding: 10px;
    justify-content: center;
    align-content: center;
}

.csc-slotlist label {
    margin: 0;
    text-align: center;
    display: block;
}

.selected-item {
    box-shadow: 0px 0px 10px #000000d1;
    background-color: #e4dd9fe0;
}

.head-style {
    font-size: 15px;
    font-weight: 500;
    text-transform: capitalize;
    margin: 0;
}

.content-style {
    font-size: 12px;
    font-weight: 500;
    text-transform: capitalize;
    margin: 0;
    color: #000000ca;
}

.csc-buttoncontainer {
    background-color: #F5EFE8;
    align-items: center;
    display: flex;
    padding: 10px;
    margin: 10px;
    border-radius: 15px;
}

.csc-buttoncontainer button {
    height: 40px;
    width: 150px;
    background-color: #fff;
    color: #2B69A3;
    border-radius: 40px;
    border: 1px solid #2B69A3;
    margin: 0 10px;
}

.color-change {
    color: #fff !important;
    background-color: #2B69A3 !important;
}

.viewbookings-container {
    flex: 1;
    text-align: center;
    align-content: center;
}

.viewbookings-container label {
    color: #2B69A3;
    margin: 0;
    font-size: 12px;
    font-weight: 500px;
}

.viewbookings-icon {
    height: 50px;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    width: 50px;
    background-color: #E4DD9F;
    border-radius: 50%;
    box-shadow: 3px 5px 5px #00000067;
    margin: auto;
    cursor: pointer;
}

.csc-calendercontainer {
    margin: 10px;
    display: flex;
}

.csc-calendercontainer .calender-app-main {
    flex: 9;
    padding: 0;
    border-radius: 15px;
    border: .5px solid #00000059;
    overflow: hidden;
}

.slots-box {
    flex: 4;
    padding: 0;
    box-shadow: 3px 5px 5px #00000039;
    border: .5px solid #969696;
    border-radius: 10px;
}

.slots-box-subcontent {
    display: flex;
    justify-content: space-between;
    padding: 5px 0 10px 0;
}

.slots-box-subcontent label {
    flex: 1;
    font-size: 15px;
    font-weight: 500;
    text-align: center;
}

.blue-label {
    color: #2B69A3;
    font-weight: 700;
    font-size: 15px;
    margin: auto;
}

.slots-box-head {
    height: fit-content;
    padding: 10px;
    background-color: #e4dd9fd5;
    border-radius: 10px 10px 0 0;
    text-align: center;
}

.slots-box-head label {
    margin: 0;
    color: #2B69A3;
}

.slots-box-content {
    padding: 10px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.slots-list-wrapper {
    flex-grow: 1;
}

.slots-box-content button {
    align-self: center;
    margin-top: 10px;
}

.doctor-list-container {
    overflow-y: auto;
    height: 320px;
}

.doctor-list-container::-webkit-scrollbar {
    width: 5px;
}

.doctor-list-container::-webkit-scrollbar-track {
    background: #FFFFFF;
}

.doctor-list-container::-webkit-scrollbar-thumb {
    background-color: #e4dd9f48;
    border-radius: 10px;
}

.doctor-list-container::-webkit-scrollbar-thumb:hover {
    background: #e4dd9f68;
}

.fc th {
    background-color: #2B69A3 !important;
    color: #fff;
    font-size: 13px;
}

.csc-calendercontainer {
    margin: 10px;
    display: flex;
}

.slots-box {
    flex: 4;
    padding: 0;
    box-shadow: 3px 5px 5px #00000039;
    border: .5px solid #969696;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
}

.slots-box-head {
    height: fit-content;
    padding: 10px;
    background-color: #e4dd9fd5;
    border-radius: 10px 10px 0 0;
    text-align: center;
}

.slots-box-head label {
    margin: 0;
    color: #2B69A3;
}

.slots-box-content {
    padding: 10px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.calender-app-main .react-calendar {
    width: 100%;
    border: none;
}

.calender-app-main .react-calendar__tile {
    height: 60px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ddd;
}

.calender-app-main .react-calendar__tile abbr {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 18px;
}

.calender-app-main .react-calendar__tile.highlight abbr {
    background-color: green;
    color: white;
}

.calender-app-main .react-calendar__month-view__weekdays {
    background-color: #2B69A3;
    color: #fff;
}

.calender-app-main .react-calendar__month-view__weekdays abbr {
    text-decoration: none !important;
    text-transform: capitalize;
}

.calender-app-main .react-calendar__month-view__weekdays .react-calendar__month-view__weekdays__weekday {
    width: calc(100% / 7);
    border: 1px solid #ddd !important;
}

.calender-app-main .react-calendar__month-view__days__day--neighboringMonth {
    background-color: #f5f5f5 !important;
    color: #ccc !important;
}

.calender-app-main .react-calendar__tile--now:enabled:hover,
.calender-app-main .react-calendar__tile--now:enabled:focus {
    background: #e4dd9f7e ;
}

.calender-app-main .react-calendar__tile--now {
    background: #e4dd9f7e !important;
}

.calender-app-main .react-calendar__tile--active {
    background-color: #ffff76 !important;
    color: #000 !important;
}
