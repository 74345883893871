.pagination li{
	background-color: #fafafa;
	padding:10px 15px;
	border:1px solid rgba(0,0,0,0.2);
	cursor: pointer;


}
.pagination .active {
	background-color: var(--blue);

}
.pagination .active a{
	color: white !important;
}
.pagination li a{
	font-weight: bold;
}

.disabled-class{
	background-color: #f0f0f0 !important;
	cursor:not-allowed !important;
}
.disabled-class a{
	cursor:not-allowed;
	color:grey!important;
}

.pagn{
	position:relative;
	align-content: center;

}
.pagination{
	justify-content: center;
}
.doctor-suggest {

}