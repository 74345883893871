.addmachine-container {
    width: 100%;
    padding: 0 3%;
    margin-top: 37px;
    box-sizing: border-box;
}

.addmachine-header {
    text-align: right !important;
    margin-bottom: 20px;
}

.dialysis-icon {
    margin: auto !important;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    height: 70px;
    width: 70px;
    border-radius: 50%;
    background-color: #2B69A3;
    border: 1px solid #E4DD9F;
}

.dialysis-icon2 {
    margin: auto !important;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-color: #2B69A3;
    border: 1px solid #E4DD9F;
}

.add-machine-button {
    padding: 10px 20px;
    background-color: #2B69A3;
    color: white;
    border: none;
    border-radius: 41px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.add-machine-button:hover {
    background-color: #0056b3;
}

.machine-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 40px;
    width: 100%;
    box-sizing: border-box;
}

.machine-card {
    background-color: white;
    border-radius: 8px;
    height: 180px;
    border: 0.5px solid #666666;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}

.active-div {
    background-color: #3DB642;
    border-radius: 0 0 8px 8px;
    text-align: center;
    align-content: center;
}

.active-div label {
    color: #FFFFFF;
    margin: 0;
}

.gry-color {
    background-color: #808080 !important;
}

.three-dots {
    flex: 1;
    text-align: right;
}

.machine-card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.model-name {
    font-weight: 400;
    font-size: 20px;
    margin: 0;
}

.unit-no {
    font-weight: 300;
    font-size: 18px;
    margin: 0;
}

.machine-card h3 {
    margin: 0 0 10px;
}

.machine-card p {
    margin: 5px 0;
}

.viewmachine-modal,
.addmachine-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items:center;
    height: fit-content;
    z-index: 1060;
}

.viewmachine-modal-content,
.addmachine-modal-content {
    background: white;
    padding: 10px;
    width: 350px;
    border: .5px solid #808080;
    position: relative;
    z-index: 1060;
}

.viewmachine-modal-content {
    margin: 35px 0 0 0;
}

.viewmachine-modal-content label {
    display: block;
    margin: 0;
}

.viewmachine-modal-content input[type="text"] {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.viewmachine-input {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.viewmachine-button-container,
.addmachine-button-container {
    display: flex;
    margin: 20px 10px 10px 10px;
}

.addmachine-modal-content {
    width: 500px;
}

.addmachine-head {
    text-align: center;
}

.add-machine-input label {
    color: #121111;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    margin: 0;
    padding-left: 2px;
}

.addmachine-head label {
    color: #2456AE;
    font-weight: 500;
    font-size: 20px;
    margin: 20px;
}

.done-button {
    margin: auto;
    background-color: #2B69A3;
    width: 20%;
    height: 35px;
    border-radius: 25px;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 400;
    align-self: center;
}

.switch-container {
    display: flex;
    margin: 10px 0;
}

.add-machine-input label span,
.viewmachine-modal-content label span {
    color: red;
}

.error-border-viewmachine {
    border: 1px solid red !important;
}

.modal-backdrop.show {
    opacity: .50 !important;
}

.custom-modal .modal-content {
    background: #ffffff;
}

.custom-modal .modal-header,
.custom-modal .modal-footer {
    border: none;
}

.custom-modal .modal-header {
    text-align: center;
}