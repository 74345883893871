.css-1thjgll-MuiTreeItem2-iconContainer {
    margin: 0;
}

.css-1jizds9-MuiTreeItem2-root {
    padding: 0 !important;
}

.css-sabens-MuiTreeItem2-content {
    border-radius: 0 !important;
}

.css-1qou66 {
    padding-left: 0px !important;
}

.css-1uyw2p7 {
    padding: 0px !important
}

.css-90fxzu {
    top: -50% !important;
}

.access-selection {
    width: 90%;
    margin: auto;
    border: .5px solid #ccc;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    text-transform: capitalize;
    padding: 2%;
    box-shadow: 0px 2px 5px #ccc;
    margin-bottom: 20%;
}

.access-selection-label {
    color: #21466B;
    font-weight: 600;
}

.access-selection .sub-button {
    background-color: #2B69A3;
    color: #fff;
    margin: auto;
    margin-top: 20px;
    border-radius: 25px;
    width: 30%;
    padding: 5px 0;
}

.render-div {
    display: flex;
    flex-direction: column;
}

.render-content1 {
    margin: auto;
    margin-top: 10px;
    width: 90%;
    display: flex;
    justify-content: space-between;
}

.render-content2 {
    margin: auto;
    width: 90%;
    margin-bottom: 30px;
    color: #25314C
}

.render-content3 {
    font-size: 18px;
    color: #25314C;
    font-weight: 500;
}


.tree-view {
    position: relative;
    padding-left: 20px;
    min-height: 70vh;
}

.tree-item {
    position: relative;
    list-style-type: none;
}

.tree-item::before,
.tree-item::after {
    content: '';
    position: absolute;
    left: 0;
    border-left: 1.5px solid #000;
}

.tree-item::before {
    top: -17px;
    left: -12px;
    height: 100%;
}

.tree-item::after {
    top: 16px;
    left: -12px;
    border-top: 1.5px solid #000;
    width: 20px;
}

.tree-item-children {
    padding-left: 20px;
}

.tree-item-label {
    padding: 5px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.tree-item-icon {
    z-index: 1;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 8px;
}

.folder-icon {
    margin-right: 8px;
}

.expand-icon {
    margin-right: 8px;
}

.tree-item.first-parent::before,
.tree-item.first-parent::after {
    border: none;
}