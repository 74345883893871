.outer-home {}

.outer-home img {
  margin: auto;
  margin-top: 60px;
}

.home-icon {
  vertical-align: middle;
  border-width: 5px;
  width: 120px;
  height: 120px;
  font-size: 70px !important;
  padding: 20px;
  border-style: solid;
  margin-bottom: 10px;
  transition: transform .8s;
  border-radius: 200px;
  cursor: pointer;
  color: #6edcd1 !important
}

.home-tiles {
  border-radius: 50px;
  /* cursor: pointer; */
  height: 200px;
  transition: transform .8s;
  /* width: 206px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 10px 10px;
  flex-basis: 18%;
  padding: 10px;
  /* box-shadow: 2px 2px 5px purple; */
}

.home-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 20px;
}

.each-title {
  transition: transform .8s;
  font-weight: 800;
  cursor: pointer;
  font-size: 18px !important;
}

.home-icon:hover {
  -ms-transform: scale(1.1);
  /* IE 9 */
  -webkit-transform: scale(1.1);
  /* Safari 3-8 */
  transform: scale(1.1);
  color: #2b69a3 !important;
  box-shadow: 1px 1px 10px;
}

.each-title:hover {
  color: #2b69a3;
  ;
  -ms-transform: scale(1.1);
  /* IE 9 */
  -webkit-transform: scale(1.1);
  /* Safari 3-8 */
  transform: scale(1.1);
}

.home-navbar {
  background-color: #2b69a3;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-content: center;
  box-shadow: 3px 5px 10px rgba(0, 0, 0, 0.2);
  height: 65px;

  position: sticky;
  top: 0;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
}

/* Add this CSS to your styles or a specific CSS file */
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #09f;
  animation: spin 1s ease infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}