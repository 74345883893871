.drs-container {
	display: flex;
	flex-direction: row !important;
	flex-wrap: wrap;
	justify-content: center;
	margin-bottom: 30px;
}

.title-of-hospital {
	color: #007bff;
	text-transform: uppercase;
	font-weight: 800;
	margin-top: 30px;

}

.dr-btn {

	margin-top: 10px;
	margin-bottom: 10px;
}

.liner,
.quick-links {
	width: 100%;


}

.drlist-page {
	padding-bottom: 40px;

}

.side-btn {
	height: 40px;
	background-color: #808080;
	color: #FFFFFF;
	border-radius: 10px;
	margin-bottom: 10px;
	text-align: left;
}

.selected-btn {
	height: 40px;
	background-color: #2B69A3;
	color: #FFFFFF;
	border-radius: 10px;
	margin-bottom: 10px;
	text-align: left;
}