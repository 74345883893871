.discharge .react-date-picker {
    height: 46px !important;
}

.doctorlist-item {
    color: #000;
    display: flex;
    flex-direction: column;
    margin: 0;
}

.doctorlist-discharge {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 10;
    height: fit-content;
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid #ccc;
    background-color: #fff;
}

.cap-sentence::first-letter {
    text-transform: capitalize;
}

.side-headings {
    font-weight: 600;
    text-align: left;
    color: #515050;
}