.procedure-table .rt-table .rt-thead {
    background-color: var(--dark-teal) !important;
}
.hideme{
    display: none;
}
.status-procedure{
    display: inline-flex;
    gap: 20px;
    background-color: #F5F5F5;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0 2px 2px grey;
}

.status-label{
    background-color: white;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
    transition: all .2s ease-in-out !important;
}
.status-label :hover .status-label:active, .status-procedure input:hover+label, .status-procedure input:active+label{
    transform: scale(1.2) !important;
    background-color: rgb(24, 185, 24);
    border-color: rgb(24, 185, 24) !important;
}

#procedure-table-list input[type=radio]:checked + .status-label{
    background-color: green;
    border-color: green !important;
}
.status-procedure :nth-child(2){
    border: 2px solid green;
}
.status-procedure :nth-child(4){
    border: 2px solid purple;
}
.status-procedure :nth-child(6){
    border: 2px solid blue;
}
.status-procedure :nth-child(8){
    border: 2px solid rgb(255, 0, 128);
}

.invoice-label{
    border: 2px solid #5587b5;
    background-color: white;
    padding: 5px;
    width:20%; 
    border-radius: 5px;
    cursor: pointer;
    transition: all .2s ease-in-out !important;
}
.invoice-label :hover .invoice-label:active, .invoice-radio input:hover+label, .invoice-radio input:active+label{
    transform: scale(1.2) !important;
    background-color: #5587b5;
    color:white;
    border-color: #5587b5 !important;
}
.invoice-radio input[type=radio]:checked + .invoice-label{
    background-color: #5587b5;
    color:white;
    border-color: #5587b5 !important;
}
.invoice-radio{
    gap:10px;
    align-items: center;
}

.invoice-print-size{
    max-width: 800px !important;
    margin-left: auto!important;
    margin-right: auto!important;
}
.invoice-inline{
    display: inline-flex;
    flex-direction: column;
    justify-content: flex-start;

    align-self: flex-start;
    align-items: flex-start;
}
.center-hosp-name{
    margin-left: auto;
    margin-right: auto;
}
.row4-grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: flex-start;
}
.discount-input{
    width: 50%;
    height: 30% !important;
}
.balance-highlight{
    background-color: gray;
    padding: 5px;
    border-radius: 5px;
    color: white;
}
.paid-highlight{
    color: black;
}

@media only screen and (min-width: 600px) {
    .comment-modal-width {
      max-width: 700px;
      
    
       /* New width for default modal */
    }
    .comment-modal-width {
      max-width: 700px;
   
       }
  }